export enum PostTypeInProject {
  WpPost = "WpPost",
  WpPage = "WpPage",
  WpFundamental = "WpFundamental",
}

export const urlPrefixMap: {
  [key in PostTypeInProject]: string | undefined;
} = {
  [PostTypeInProject.WpPost]: "wissen",
  [PostTypeInProject.WpFundamental]: "wissen/grundlagen",
  [PostTypeInProject.WpPage]: undefined,
};

// This enum matches the template names from Advanced Custom Fields
export enum Template {
  Default = "default",
  Home = "home",
  KnowledgeDatabase = "knowledge-database",
  DataProtection = "data-protection",
  Imprint = "imprint",
  Faq = "faq",
  UberResolvio = "uber-resolvio",
}

/**
 * Number of results per request.
 * Should usually be set to 100, which is the wordpress maximum.
 *
 * To mess around, you can change the value, but leave the type,
 *  which will give you a nice warning if you forget about it.
 */
// eslint-disable-next-line @typescript-eslint/prefer-as-const
export const resultsPerPage: 100 = 100;
