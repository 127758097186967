import { Box, Chip, Typography } from "@material-ui/core";
import theme from "../../../layouts/theme";

export interface RVOFeatureGridCardProps {
  /*
   *
   */
  children?: React.ReactNode;
  title: string;
  text: string;
  isPlannedFeature?: boolean;
  icon?: JSX.Element;
}

const RVOFeatureGridCard = ({
  children,
  title,
  text,
  isPlannedFeature,
  icon,
}: RVOFeatureGridCardProps): JSX.Element => {
  return (
    <Box
      style={{
        backgroundColor: isPlannedFeature
          ? "white"
          : theme.palette.secondary.light,
        borderRadius: theme.shape.borderRadius,
        border: isPlannedFeature
          ? `4px solid ${theme.palette.secondary.main}`
          : undefined,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        <Box padding="2rem" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            style={{ gap: "0.5rem" }}
            justifyContent="space-between"
          >
            <Typography variant="h3">{title}</Typography>

            <Typography
              style={{
                color: theme.palette.primary.dark,
              }}
            >
              {icon && icon}
            </Typography>

            {isPlannedFeature && <Chip color="primary" label="coming soon" />}
          </Box>

          <Typography style={{ maxWidth: "80%" }}>{text}</Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default RVOFeatureGridCard;
