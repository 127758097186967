import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { Template } from "../../../config/wordPressConfig";
import { usePageForTemplate } from "../../queries/wpPage/usePageForTemplate";
import RVOHomeTemplate from "../04-templates/RVOHomeTemplate/RVOHomeTemplate";

export const IndexPage = (): JSX.Element => {
  const homePage = usePageForTemplate(Template.Home);

  return (
    <>
      <Seo post={homePage} />

      <RVOHomeTemplate pageId={homePage?.id as string} />
    </>
  );
};

export default IndexPage;
