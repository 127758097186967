import { Box, Grid } from "@material-ui/core";
import {
  AccountTree,
  CalendarToday,
  GroupWork,
  PeopleAlt,
  PictureAsPdf,
  PieChart,
  PinDrop,
  ThumbUp,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import theme from "../../../layouts/theme";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import RVOFeatureCardTemplates from "../../02-molecules/RVOFeatureCardTemplates/RVOFeatureCardTemplates";
import RVOFeatureGridCard from "../../02-molecules/RVOFeatureGridCard/RVOFeatureGridCard";

const RVOFeatureGrid = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={6} style={{ flex: 1 }}>
        <RVOFeatureGridCard
          title={t("home.features.overview.title")}
          text={t("home.features.overview.text")}
          icon={<AccountTree fontSize="large" />}
        >
          <Box width="90%">
            <RVOAnimateFade delay={0.3}>
              <img
                src="/app/screenshots/workspace_switcher.png"
                width="100%"
                height="auto"
                style={{
                  borderTopRightRadius: theme.shape.borderRadius,
                  borderBottomLeftRadius: theme.shape.borderRadius,
                  borderTop: `2px solid ${theme.palette.secondary.main}`,
                  borderRight: `2px solid ${theme.palette.secondary.main}`,
                  display: "block",
                }}
              />
            </RVOAnimateFade>
          </Box>
        </RVOFeatureGridCard>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box>
            <RVOFeatureCardTemplates />
          </Box>

          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.pdfExport.title")}
              text={t("home.features.pdfExport.text")}
              icon={<PictureAsPdf fontSize="large" />}
            >
              <Box width="60%">
                <RVOAnimateFade delay={0.3}>
                  <img
                    src="/app/features/pdf_export.png"
                    width="100%"
                    height="auto"
                    style={{
                      borderTopRightRadius: theme.shape.borderRadius,
                      borderBottomLeftRadius: theme.shape.borderRadius,
                      borderTop: `2px solid ${theme.palette.secondary.main}`,
                      borderRight: `2px solid ${theme.palette.secondary.main}`,
                      display: "block",
                    }}
                  />
                </RVOAnimateFade>
              </Box>
            </RVOFeatureGridCard>
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.reminders.title")}
              text={t("home.features.reminders.text")}
              icon={<CalendarToday fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.collaboration.title")}
              text={t("home.features.collaboration.text")}
              icon={<PeopleAlt fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.approvals.title")}
              text={t("home.features.approvals.text")}
              icon={<ThumbUp fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.sharesForEmployees.title")}
              text={t("home.features.sharesForEmployees.text")}
              icon={<GroupWork fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.presenceMode.title")}
              text={t("home.features.presenceMode.text")}
              icon={<PinDrop fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item={true} xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ gap: "2rem" }}
        >
          <Box
            style={{
              flex: 1,
            }}
          >
            <RVOFeatureGridCard
              title={t("home.features.shares.title")}
              text={t("home.features.shares.text")}
              icon={<PieChart fontSize="large" />}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RVOFeatureGrid;
