import { Chip } from "@material-ui/core";
import Marquee from "react-fast-marquee";
import { LegacyTemplateLandingpage } from "../../../helpers/createVorlageLandingpage";
import theme, { fontWeightBold } from "../../../layouts/theme";

interface RVOTemplateChipMarqueeProps {
  vorlagen: LegacyTemplateLandingpage[];
  direction?: "left" | "right";
}

const RVOTemplateChipMarquee = ({
  vorlagen,
  direction,
}: RVOTemplateChipMarqueeProps): JSX.Element => {
  return (
    <Marquee speed={15} direction={direction || "left"}>
      {vorlagen.map((vorlage) => (
        <Chip
          key={vorlage.kurzBezeichnung}
          label={vorlage.kurzBezeichnung}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginRight: theme.spacing(1),
            fontWeight: fontWeightBold,
          }}
        />
      ))}
    </Marquee>
  );
};

export default RVOTemplateChipMarquee;
