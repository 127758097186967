import { Box } from "@material-ui/core";
import Marquee from "react-fast-marquee";

export interface MarqueePartner {
  logo: string;
}

const partners: MarqueePartner[] = [
  {
    logo: "/app/partner/lbank.png",
  },
  {
    logo: "/app/partner/deutsche-telekom.png",
  },
  {
    logo: "/app/partner/technologiepark-hb.svg",
  },
  {
    logo: "/app/partner/techboost.png",
  },
  {
    logo: "/app/partner/startup_bw.svg",
  },
  {
    logo: "/app/partner/dieter_schwarz_stiftung.jpeg",
  },
  {
    logo: "/app/partner/gold.jpeg",
  },
  {
    logo: "/app/partner/itstadt_ko.png",
  },

  {
    logo: "/app/partner/campus_founders.jpeg",
  },
];

export const RVOPartnerMarquee = (): JSX.Element => {
  return (
    <Marquee
      style={{ filter: "grayscale(100%)" }}
      speed={10}
      gradient={true}
      autoFill={true}
    >
      {partners.map((partner, i) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          width={200}
          height="auto"
          paddingLeft={2}
          paddingRight={2}
          overflow="hidden"
          justifyContent="center"
          display="flex"
        >
          <img width="100%" height="auto" src={partner.logo} />
        </Box>
      ))}
    </Marquee>
  );
};
