/* eslint-disable i18next/no-literal-string */
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { TranslatedString } from "@nvon/react-toolbox";
import { useTranslation } from "react-i18next";
import { fontWeightBold } from "../../../layouts/theme";
import RVOTestimonialCard from "../RVOTestimonialCard/RVOTestimonialCard";

export interface Testimonial {
  name: TranslatedString;
  role: TranslatedString;
  citation: TranslatedString;
  avatar: string | undefined;
}

interface TestimonialAvatars {
  [key: string]: string | undefined;
}

const testimonialAvatars: TestimonialAvatars = {
  timBicker: "/app/testimonials/timBicker.png",
  hamedRoknizadeh: "/app/testimonials/hamedRoknizadeh.png",
};

const getTestimonial = (testimonialName: string): Testimonial => {
  const obj = {
    name: (t) =>
      t(`home.testimonials.${testimonialName}.name` as keyof typeof t),
    role: (t) =>
      t(`home.testimonials.${testimonialName}.role` as keyof typeof t),
    citation: (t) =>
      t(`home.testimonials.${testimonialName}.citation` as keyof typeof t),
    avatar: testimonialAvatars[testimonialName] || undefined,
  } as Testimonial;
  return obj;
};

const RVOTestimonialSection = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const testimonials = [
    getTestimonial("timBicker"),
    getTestimonial("leonhardB"),
    getTestimonial("joachimR"),
    getTestimonial("hamedRoknizadeh"),
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isSmallScreen ? "4rem" : "6rem",
      }}
    >
      <Box
        style={{
          marginLeft: isSmallScreen ? 0 : "2rem",
          marginRight: isSmallScreen ? 0 : "2rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: fontWeightBold,
            color: theme.palette.secondary.light,
            textAlign: "center",
          }}
        >
          {t("home.testimonials.pretitle")}
        </Typography>

        <Typography
          component="h2"
          variant="h1"
          style={{ color: theme.palette.secondary.light }}
        >
          {t("home.testimonials.title")}
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: isSmallScreen ? theme.spacing(2) : theme.spacing(3),
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {testimonials.map((testimonial) => {
          return (
            <RVOTestimonialCard
              testimonial={testimonial}
              key={testimonial.name(t)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default RVOTestimonialSection;
