import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { TranslatedString } from "@nvon/react-toolbox";
import { t } from "i18next";
import theme, { fontWeightBold } from "../../../layouts/theme";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";

interface RVOWhatSectionContent {
  title: TranslatedString;
  description: TranslatedString;
  imageSrc: string;
  reverseLayout?: boolean;
}

const whatSections: RVOWhatSectionContent[] = [
  {
    title: t("home.what.boardManagement.title"),
    description: t("home.what.boardManagement.description"),
    imageSrc: "/app/what/orga.png",
  },
  {
    title: t("home.what.process.title"),
    description: t("home.what.process.description"),
    reverseLayout: true,
    imageSrc: "/app/what/board.png",
  },
  {
    title: t("home.what.timeSaver.title"),
    description: t("home.what.timeSaver.description"),
    imageSrc: "/app/what/zeitersparnis.png",
  },
];

const RVOWhatSection = (): JSX.Element => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const determineFlexDirection = (
    section: RVOWhatSectionContent,
    smallScreen: boolean
  ) => {
    if (section.reverseLayout && !smallScreen) {
      return "row-reverse";
    } else if (smallScreen) {
      return "column";
    } else {
      return "row";
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isSmallScreen ? "6rem" : "8rem",
      }}
    >
      <Box
        style={{
          marginLeft: isSmallScreen ? 0 : "2rem",
          marginRight: isSmallScreen ? 0 : "2rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: fontWeightBold,
            color: theme.palette.warning.dark,
            textAlign: "center",
          }}
        >
          {t("home.what.pretext")}
        </Typography>

        <Typography component="h2" variant="h1">
          {t("home.what.title")}
        </Typography>
      </Box>

      {whatSections.map((section: RVOWhatSectionContent) => {
        return (
          <Box
            key={section.title.toString()}
            style={{
              display: "flex",
              flexDirection: determineFlexDirection(section, isSmallScreen),
              gap: isSmallScreen ? "4rem" : "14rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: isSmallScreen ? "flex-start" : "center",
                width: isSmallScreen ? "100%" : "50%",
              }}
            >
              <Typography variant="h2" component="h3">
                {section.title}
              </Typography>

              <Typography variant="body1">{section.description}</Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: isSmallScreen ? "center" : "flex-start",
                width: isSmallScreen ? "80%" : "50%",
              }}
            >
              <RVOAnimateFade delay={0.4}>
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  width={!isSmallScreen && isMediumScreen ? "100%" : "80%"}
                  height="auto"
                  src={section.imageSrc}
                />
              </RVOAnimateFade>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default RVOWhatSection;
