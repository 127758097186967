import { Box } from "@material-ui/core";
import { Category } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { vorlagenInUse } from "../../../helpers/clientSide/constants";
import RVOTemplateChipMarquee from "../../01-atoms/RVOTemplateChipMarquee/RVOTemplateChipMarquee";
import RVOFeatureGridCard from "../RVOFeatureGridCard/RVOFeatureGridCard";

const RVOFeatureCardTemplates = (): JSX.Element => {
  const first20Vorlagen = vorlagenInUse.slice(0, 20);
  const second20Vorlagen = vorlagenInUse.slice(20, 40);
  const third20Vorlagen = vorlagenInUse.slice(40, 60);
  const { t } = useTranslation();

  return (
    <RVOFeatureGridCard
      title={t("home.features.templates.title")}
      text={t("home.features.templates.text")}
      icon={<Category fontSize="large" />}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: "1rem", paddingBottom: "2rem" }}
      >
        <RVOTemplateChipMarquee vorlagen={first20Vorlagen} />

        <RVOTemplateChipMarquee vorlagen={second20Vorlagen} direction="right" />

        <RVOTemplateChipMarquee vorlagen={third20Vorlagen} />
      </Box>
    </RVOFeatureGridCard>
  );
};

export default RVOFeatureCardTemplates;
