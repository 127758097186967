/* eslint-disable i18next/no-literal-string */
import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { filterNoUndefinedNoNull } from "@nvon/baseline";
import { useTranslation } from "react-i18next";
import { pricingPath } from "../../../helpers/general/frontendUrlPaths";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { fontWeightBold } from "../../../layouts/theme";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import FeaturedVideo from "../../01-atoms/FeaturedVideo/FeaturedVideo";
import RVOTrustLogos from "../../01-atoms/RVOHeroTrustLogos/RVOHeroTrustLogos";
import RVOLinkButton from "../../01-atoms/RVOLinkButton/RVOLinkButton";
import RVORegisterNowCtaButton from "../../01-atoms/RVORegisterNowCtaButton/RVORegisterNowCtaButton";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import {
  RVOBackgroundColor,
  RVOBackgroundGradient,
  RVOOverflow,
} from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import { RVOPartnerMarquee } from "../../02-molecules/RVOPartnerMarquee/RVOPartnerMarquee";
import {
  benefitsSection,
  comparisonSection,
  demoSection,
  faqSection,
  featuresSection,
  lawyerPoweredSection,
  partnersSection,
  resolvioExplainVideoSection,
  reviewsSection,
} from "../../03-organisms/NVONHeader/NVONPageSections/NVONPageSections";
import { RVOComparisonSection } from "../../03-organisms/RVOComparisonSection/RVOComparisionSection";
import RVOFaqComponent from "../../03-organisms/RVOFaqComponent/RVOFaqComponent";
import RVOFeatureGrid from "../../03-organisms/RVOFeatureGrid/RVOFeatureGrid";
import RVOHeroSection from "../../03-organisms/RVOHeroSection/RVOHeroSection";
import RVOLawyerPoweredSection from "../../03-organisms/RVOLawyerPoweredSection/RVOLawyerPoweredSection";
import RVOWhatSection from "../../03-organisms/RVOWhatSection/RVOWhatSection";
import RVOTestimonialSection from "../RVOTestimonialSection/RVOTestimonialSection";

const RVOHomeTemplate = (props: DefaultPageProps): JSX.Element => {
  const faqs = (
    useSinglePage(props.pageId || "")?.pageFields?.home?.faq?.questions || []
  ).filter(filterNoUndefinedNoNull);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <RVOContainer
        fullWidth={true}
        overflow={RVOOverflow.Hidden}
        paddingBottom={true}
        background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
      >
        <RVOHeroSection pageId={props.pageId} />
      </RVOContainer>

      <RVOContainer id={partnersSection.sectionId}>
        <Box marginBottom={2} justifyContent="center" display="flex">
          <Typography variant="subtitle2">
            {t("home.partners.title")}
          </Typography>
        </Box>

        <RVOPartnerMarquee />
      </RVOContainer>

      <RVOContainer paddingBottom={true} id={benefitsSection.sectionId}>
        <RVOWhatSection />
      </RVOContainer>

      <RVOContainer
        paddingBottom={true}
        id={lawyerPoweredSection.sectionId}
        background={RVOBackgroundColor.PrimaryDarkBlue}
      >
        <RVOLawyerPoweredSection />
      </RVOContainer>

      <RVOContainer paddingBottom={true} id={featuresSection.sectionId}>
        <Box
          style={{
            marginLeft: isSmallScreen ? 0 : "2rem",
            marginRight: isSmallScreen ? 0 : "2rem",
            textAlign: "center",
            marginBottom: isSmallScreen ? "6rem" : "8rem",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: fontWeightBold,
              color: theme.palette.warning.dark,
              textAlign: "center",
            }}
          >
            {t("home.features.pretitle")}
          </Typography>

          <Typography component="h2" variant="h1">
            {t("home.features.title")}
          </Typography>
        </Box>

        <RVOFeatureGrid />
      </RVOContainer>

      {/* Wird in kommendem PR ergänzt */}

      {/* <RVOContainer paddingBottom={true}>
        <Box className={classes.featureSection}>
          <Typography variant="h2">
            Die Koordination deiner Gremien ist zeitaufwendig und komplex hier
            inhaltlich: Für Gesellschafter & Vereinsmitglieder, Vorstände &
            Geschäftsführer, Aufsichtsräte & Beiräte
          </Typography>

          <Typography variant="body1">
            Mit Resolvio digitalisierst du die Vorbereitung, Abstimmung und
            Dokumentation.
            <br /> Ob Gesellschafterkreis oder AG-Vorstand – Abstimmungsprozesse
            sind dank passender Vorlagen,

            <br /> digitalen Unterschriften und automatischer Protokollierung
            transparent und effizient.
          </Typography>

          <RVOFeatureTabs />
        </Box>
      </RVOContainer> */}

      <RVOContainer
        paddingBottom={true}
        id={resolvioExplainVideoSection.sectionId}
      >
        <Box marginBottom={2} justifyContent="center" display="flex">
          <Typography variant="h3">
            {resolvioExplainVideoSection.title(t)}
          </Typography>
        </Box>

        <FeaturedVideo
          schemaTitle={resolvioExplainVideoSection.title(t)}
          schemaDescription={t("home.hero.claim")}
          schemaUploadDate={"01.05.2024"}
          schemaContentUrl="https://cms.resolvio.de/wp-content/uploads/2024/05/05094.mp4"
          schemaThumbnailUrl={"/app/screenshots/das-ist-resolvio-in-90-sec.png"}
          schemaRegionsAllowed="DE, AT, CH"
          videoUrl="https://cms.resolvio.de/wp-content/uploads/2024/05/05094.mp4"
          posterImage={"/app/screenshots/das-ist-resolvio-in-90-sec.png"}
          minimizeOnlyWhilePlaying={true}
          playing={false}
        />
      </RVOContainer>

      <RVOContainer
        paddingBottom={true}
        background={RVOBackgroundColor.PrimaryDarkBlue}
        id={reviewsSection.sectionId}
      >
        <RVOTestimonialSection />
      </RVOContainer>

      <RVOContainer paddingBottom={true} id={comparisonSection.sectionId}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <RVOComparisonSection />
        </Box>
      </RVOContainer>

      <RVOContainer>
        <Card style={{ borderRadius: 10, padding: 0 }}>
          <Grid container={true}>
            <Grid item={true} xs={12} md={4}>
              <img
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                src="/app/user/laptop.jpeg"
              />
            </Grid>

            <Grid item={true} xs={12} md={8}>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  gap: "2rem",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }}
                padding={isSmallScreen ? 2 : 4}
                height="100%"
              >
                <Typography variant="h3" style={{ marginBottom: 0 }}>
                  {t("home.cta.headline")}
                </Typography>

                <Typography>{t("home.cta.text")}</Typography>

                <Box
                  display="flex"
                  flexDirection={isSmallScreen ? "column" : "row"}
                  style={{ gap: "1rem" }}
                >
                  <RVORegisterNowCtaButton />

                  <RVOLinkButton
                    href={pricingPath}
                    buttonProps={{ variant: "outlined" }}
                  >
                    {t("home.hero.buttons.comparePrices")}
                  </RVOLinkButton>
                </Box>

                <Box>
                  <RVOAnimateFade delay={0.4}>
                    <RVOTrustLogos />
                  </RVOAnimateFade>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </RVOContainer>

      <RVOContainer paddingBottom={false} id={demoSection.sectionId}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",

              width: isSmallScreen ? "100%" : "70%",
            }}
          >
            <Box
              style={{
                marginLeft: isSmallScreen ? 0 : "2rem",
                marginRight: isSmallScreen ? 0 : "2rem",
                textAlign: "center",
                marginBottom: "2rem",
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: fontWeightBold,
                  color: theme.palette.warning.dark,
                  textAlign: "center",
                }}
              >
                {t("home.demo.pretitle")}
              </Typography>

              <Typography component="h2" variant="h1">
                {t("home.demo.title")}
              </Typography>
            </Box>

            <Typography variant="body1" style={{ marginBottom: 0 }}>
              {t("home.demo.subtitle")}
            </Typography>
          </Box>

          <iframe
            frameBorder="0"
            width={!isSmallScreen ? "100%" : 450}
            height={1000}
            src="https://meet.brevo.com/resolvio-demo/borderless?l=30-minutiges-meeting"
          />
        </Box>
      </RVOContainer>

      <RVOContainer
        paddingBottom={true}
        background={RVOBackgroundColor.SecondaryLightBlue}
        id={faqSection.sectionId}
      >
        <RVOFaqComponent faqs={faqs} />
      </RVOContainer>
    </>
  );
};

export default RVOHomeTemplate;
