// / <reference path="../../../types/react-simple-rotating-text.d.ts" />
import {
  Box,
  Button,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// the package says, it has no export "RotatingText", but it works...
// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import { ArrowForward, CallMade } from "@material-ui/icons";
import theme, { fontWeightBold } from "../../../layouts/theme";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";

const RVOLawyerPoweredSection = (): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const keyfacts = [
    t("home.lawyerPowered.keyfacts.1"),
    t("home.lawyerPowered.keyfacts.2"),
    t("home.lawyerPowered.keyfacts.3"),
    t("home.lawyerPowered.keyfacts.4"),
  ];

  const hubiUrls = {
    picture: "/app/cofounder/hubertus-scherbarth-small.png",
    profile: "/wissen/autor/hubertus-scherbarth,-ll.m,-b.a",
  };

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: isSmallScreen ? "6rem" : "8rem",
          width: isSmallScreen ? "100%" : "70%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isSmallScreen ? "flex-start" : "center",
            color: theme.palette.secondary.main,
          }}
        >
          <Box
            style={{
              marginLeft: isSmallScreen ? 0 : "2rem",
              marginRight: isSmallScreen ? 0 : "2rem",
              textAlign: "center",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: fontWeightBold,
                color: theme.palette.warning.main,
                textAlign: "center",
              }}
            >
              {t("home.lawyerPowered.pretitle")}
            </Typography>

            <Typography
              component="h2"
              variant="h1"
              style={{
                color: "inherit",
              }}
            >
              {t("home.lawyerPowered.title")}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection={!isSmallScreen ? "row" : "column-reverse"}
            style={{ gap: theme.spacing(3) }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Box display="flex" flexDirection={"column"}>
              <Box marginBottom={2} justifyContent="center" display="flex">
                <Typography
                  variant="body1"
                  style={{
                    color: "inherit",
                  }}
                >
                  <HtmlFromLangFile
                    contentContainingHtml={t("home.lawyerPowered.text1")}
                  />
                </Typography>
              </Box>

              <Box marginBottom={2} justifyContent="center" display="flex">
                <Typography
                  variant="body1"
                  style={{
                    color: "inherit",
                  }}
                >
                  {t("home.lawyerPowered.text2")}
                </Typography>
              </Box>

              <List>
                {keyfacts.map((keyfact) => {
                  return (
                    <ListItem button={false} key={keyfact}>
                      <ListItemIcon
                        style={{
                          flexShrink: 1,
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <ArrowForward />

                        <ListItemText
                          primary={keyfact}
                          style={{
                            marginLeft: theme.spacing(1),
                          }}
                          primaryTypographyProps={{
                            variant: "h5",
                            color: "inherit",
                          }}
                        />
                      </ListItemIcon>
                    </ListItem>
                  );
                })}
              </List>
            </Box>

            <ButtonBase href={hubiUrls.profile}>
              <Box
                display="flex"
                flexDirection={"column"}
                style={{ gap: theme.spacing(2) }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <RVOAnimateFade>
                  <img
                    src={hubiUrls.picture}
                    alt="Rechtsanwalt Hubertus Scherbarth"
                    style={{ width: "250px", height: "auto" }}
                  />
                </RVOAnimateFade>

                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                  style={{ gap: theme.spacing(0.5) }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: "inherit",
                      marginTop: theme.spacing(1),
                      fontWeight: fontWeightBold,
                    }}
                  >
                    {t("home.lawyerPowered.hubertusScherbarth.name")}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{
                      color: "inherit",
                      textAlign: "center",
                    }}
                  >
                    {t("home.lawyerPowered.hubertusScherbarth.role")}
                  </Typography>
                </Box>

                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  href={hubiUrls.profile}
                  startIcon={<CallMade />}
                >
                  {t("general.learnMore")}
                </Button>
              </Box>
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RVOLawyerPoweredSection;
