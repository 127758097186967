import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOHeroSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: "center",
      position: "relative",
    },
    infoWrapper: {
      position: "relative",
      zIndex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "left",
      "& > div": {
        marginBottom: theme.spacing(2),
      },
    },
    title: {
      marginBottom: theme.spacing(0),
    },

    statusAlert: {
      width: "70%",
      marginBottom: "2rem",
      border: "2px solid #ddbf74",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    buttonWrapper: {
      "& a:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    firstActionButton: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
      },
    },
    actionButton: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    factsWrapper: {
      display: "flex",
      flexDirection: "row",
      columnGap: theme.spacing(2),
      rowGap: theme.spacing(1),
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    factWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      columnGap: theme.spacing(1),
      width: "36%",
      [theme.breakpoints.down("md")]: {
        width: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    iconWrapper: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
      backgroundColor: theme.palette.background.default,
    },
    appImagesWrapper: {
      height: "100%",
      position: "relative",
      transform: "translate(5%, -15%)",
      [theme.breakpoints.down("md")]: {
        transform: "translate(5%, 0%)",
      },
      minHeight: "fit-content",
      overflow: "visible",
    },
    beschlussViewScreenshot: {
      boxShadow: [
        "0px 1px 1px rgba(3, 7, 18, 0.02)",
        "0px 3px 3px rgba(3, 7, 18, 0.04)",
        "0px 6px 6px rgba(3, 7, 18, 0.06)",
        "0px 10px 10px rgba(3, 7, 18, 0.08)",
        "0px 16px 16px rgba(3, 7, 18, 0.10)",
      ].join(", "),
      borderRadius: theme.shape.borderRadius,
    },
    signWrapper: {
      position: "absolute",
      bottom: 0,
      left: 0,
      transform: "translate(-10%, 50%)",
      [theme.breakpoints.down("md")]: {
        transform: "translate(0%, 20%)",
      },
      borderRadius: theme.shape.borderRadius,
    },
    signWrapperShadow: {
      boxShadow: [
        "0px 1px 1px rgba(3, 7, 18, 0.02)",
        "0px 5px 5px rgba(3, 7, 18, 0.04)",
        "0px 12px 12px rgba(3, 7, 18, 0.06)",
        "0px 20px 20px rgba(3, 7, 18, 0.08)",
        "0px 32px 32px rgba(3, 7, 18, 0.10)",
      ].join(", "),
    },
  })
);
