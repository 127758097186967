/* eslint-disable i18next/no-literal-string */
import { Avatar, Box, Grid, Typography, useTheme } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Testimonial } from "../RVOTestimonialSection/RVOTestimonialSection";

interface RVOTestimonialCardProps {
  testimonial: Testimonial;
}

const RVOTestimonialCard = ({
  testimonial,
}: RVOTestimonialCardProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container={true}
      style={{
        backgroundColor: theme.palette.secondary.light,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        gap: theme.spacing(2),
        maxWidth: "400px",
      }}
    >
      <Grid item={true} xs={12}>
        <Box display="flex" flexDirection="column" style={{ gap: "1rem" }}>
          <Typography component="p" variant="body1">
            {testimonial.citation(t)}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item={true}
        xs={12}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          style={{
            gap: "1rem",
          }}
          alignItems="center"
        >
          <Avatar
            src={testimonial.avatar}
            alt={testimonial.name(t)}
            style={{
              width: 60,
              height: 60,
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.secondary.light,
            }}
          />

          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: theme.spacing(0.6) }}
          >
            <Box
              display="flex"
              flexDirection="row"
              style={{ gap: theme.spacing(1) }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {testimonial.name(t)}
              </Typography>

              <Rating size={"small"} value={5} readOnly={true} />
            </Box>

            <Typography variant="body2">{testimonial.role(t)}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RVOTestimonialCard;
