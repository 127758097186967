import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { fontWeightBold } from "../../../layouts/theme";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import {
  ComparisionRowTitleDescription,
  ComparisonRows,
  RVOComparisonTable,
} from "../RVOComparisonTable/RVOComparisonTable";

enum Rows {
  Versenden = "Versenden",
  Signieren = "Signieren",
  AlleBeschlussSchritte = "AlleBeschlussSchritte",
  GremienManagen = "GremienManagen",
  Vorlagen = "Vorlagen",
  Sicherheit = "Sicherheit",
  SingleSourceOfTruth = "SingleSourceOfTruth",
  Kollaboration = "Kollaboration",
}

const rowData: Record<Rows, ComparisionRowTitleDescription> = {
  [Rows.Versenden]: {
    rowTitle: (t) => t("home.comparison.versenden.rowTitle"),
    rowDescription: (t) => t("home.comparison.versenden.rowDescription"),
    rowExplanation: (t) => t("home.comparison.versenden.rowExplanation"),
  },
  [Rows.Signieren]: {
    rowTitle: (t) => t("home.comparison.signieren.rowTitle"),
    rowDescription: (t) => t("home.comparison.signieren.rowDescription"),
    rowExplanation: (t) => t("home.comparison.signieren.rowExplanation"),
  },
  [Rows.AlleBeschlussSchritte]: {
    rowTitle: (t) => t("home.comparison.alleBeschlussSchritte.rowTitle"),
    rowDescription: (t) =>
      t("home.comparison.alleBeschlussSchritte.rowDescription"),
    rowExplanation: (t) =>
      t("home.comparison.alleBeschlussSchritte.rowExplanation"),
  },
  [Rows.GremienManagen]: {
    rowTitle: (t) => t("home.comparison.gremienManagen.rowTitle"),
    rowDescription: (t) => t("home.comparison.gremienManagen.rowDescription"),
    rowExplanation: (t) => t("home.comparison.gremienManagen.rowExplanation"),
  },
  [Rows.Vorlagen]: {
    rowTitle: (t) => t("home.comparison.vorlagen.rowTitle"),
    rowDescription: (t) => t("home.comparison.vorlagen.rowDescription"),
    rowExplanation: (t) => t("home.comparison.vorlagen.rowExplanation"),
  },
  [Rows.Sicherheit]: {
    rowTitle: (t) => t("home.comparison.sicherheit.rowTitle"),
    rowDescription: (t) => t("home.comparison.sicherheit.rowDescription"),
    rowExplanation: (t) => t("home.comparison.sicherheit.rowExplanation"),
  },
  [Rows.SingleSourceOfTruth]: {
    rowTitle: (t) => t("home.comparison.singleSourceOfTruth.rowTitle"),
    rowDescription: (t) =>
      t("home.comparison.singleSourceOfTruth.rowDescription"),
    rowExplanation: (t) =>
      t("home.comparison.singleSourceOfTruth.rowExplanation"),
  },
  [Rows.Kollaboration]: {
    rowTitle: (t) => t("home.comparison.kollaboration.rowTitle"),
    rowDescription: (t) => t("home.comparison.kollaboration.rowDescription"),
    rowExplanation: (t) => t("home.comparison.kollaboration.rowExplanation"),
  },
};

enum ColumnTitles {
  Resolvio = "Resolvio",
  Email = "Email",
  DocuSign = "DocuSign",
}

export const comparisonEmailResolvio: ComparisonRows[] = [
  {
    rowData: rowData.Versenden,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✔" },
      { columnTitle: ColumnTitles.Email, value: "✔" },
    ],
  },
  {
    rowData: rowData.Signieren,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✔" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
  {
    rowData: rowData.AlleBeschlussSchritte,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✖" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
  {
    rowData: rowData.Vorlagen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✖" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
  {
    rowData: rowData.SingleSourceOfTruth,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✖" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
  {
    rowData: rowData.GremienManagen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✖" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
  {
    rowData: rowData.Kollaboration,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.DocuSign, value: "✖" },
      { columnTitle: ColumnTitles.Email, value: "✖" },
    ],
  },
];

export const RVOComparisonSection = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isSmallScreen ? "6rem" : "8rem",
        width: isSmallScreen ? "100%" : "70%",
      }}
    >
      <RVOAnimateFade>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isSmallScreen ? "flex-start" : "center",
          }}
        >
          <Box
            style={{
              marginLeft: isSmallScreen ? 0 : "2rem",
              marginRight: isSmallScreen ? 0 : "2rem",
              textAlign: "center",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: fontWeightBold,
                color: theme.palette.warning.dark,
                textAlign: "center",
              }}
            >
              {t("home.comparison.pretitle")}
            </Typography>

            <Typography component="h2" variant="h1">
              {t("home.comparison.title")}
            </Typography>
          </Box>

          <Typography variant="body1">
            {t("home.comparison.subtitle")}
          </Typography>
        </Box>
      </RVOAnimateFade>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          alignItems: isSmallScreen ? "flex-start" : "center",
          overflow: "auto",
          width: isSmallScreen ? "96vw" : "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            maxWidth: "800px",
          }}
        >
          <RVOComparisonTable rows={comparisonEmailResolvio} />
        </Box>
      </Box>
    </Box>
  );
};
