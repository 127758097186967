// / <reference path="../../../types/react-simple-rotating-text.d.ts" />
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
// the package says, it has no export "RotatingText", but it works...
// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import { PlayArrowTwoTone } from "@material-ui/icons";
import { assertTruthy } from "@nvon/baseline";
import { useSmoothWindowScroll } from "@nvon/react-toolbox";
import { usePosthogEvent } from "../../../hooks/useTrackingEvent";
import theme from "../../../layouts/theme";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { RVOTrackingEvent } from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import RVORegisterNowCtaButton from "../../01-atoms/RVORegisterNowCtaButton/RVORegisterNowCtaButton";
import RVOAnimateFade from "../../01-atoms/animations/RVOAnimateFade/RVOAnimateFade";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { resolvioExplainVideoSection } from "../NVONHeader/NVONPageSections/NVONPageSections";
import RVOPeopleTrustResolvio from "../RVOPeopleTrustResolvio/RVOPeopleTrustResolvio";
import { RVOHeroSectionProps } from "./RVOHeroSectionInterfaces";
import { useRVOHeroSectionStyles } from "./RVOHeroSectionStyles";

const RVOHeroSection = (props: RVOHeroSectionProps): JSX.Element => {
  const classes = useRVOHeroSectionStyles();
  const hero = useSinglePage(props.pageId)?.pageFields?.home?.hero;
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { firePosthogEvent } = usePosthogEvent();

  const scrollTo = useSmoothWindowScroll();
  const scrollToSection = (sectionId: string) => {
    const element = document.querySelector(`#${sectionId}`);
    assertTruthy(element, `Did not find section with id ${sectionId}`);

    scrollTo(element);
  };

  const title = t("home.hero.title");
  const claim = t("home.hero.claim");
  const buttonLabel = hero?.buttonLabel;

  return (
    <Grid container={true} className={classes.wrapper} spacing={2}>
      <Grid
        container={true}
        item={true}
        xs={12}
        lg={6}
        className={classes.infoWrapper}
      >
        <RVOAnimateFade>
          <Grid item={true}>
            <VTSUnderlinedTitle
              titleRaw={title}
              typographyProps={{
                variant: "h1",
                className: classes.title,
              }}
              component={"h1"}
            />
          </Grid>
        </RVOAnimateFade>

        <RVOAnimateFade delay={0.1}>
          <Grid item={true}>
            <Typography variant="h5">{claim}</Typography>
          </Grid>
        </RVOAnimateFade>

        <RVOAnimateFade delay={0.2}>
          <Grid item={true} className={classes.buttonWrapper}>
            <RVORegisterNowCtaButton
              className={clsx(classes.actionButton, classes.firstActionButton)}
              label={buttonLabel}
            />

            <Button
              href={`#${resolvioExplainVideoSection.sectionId}`}
              className={classes.actionButton}
              variant="outlined"
              startIcon={<PlayArrowTwoTone />}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                scrollToSection(resolvioExplainVideoSection.sectionId);
                firePosthogEvent({
                  eventId: RVOTrackingEvent.WatchDemoVideoClicked,
                });
                // Prevent flash before scrolling
                event.preventDefault();
              }}
            >
              {t("home.hero.buttons.watchVideo")}
            </Button>
          </Grid>
        </RVOAnimateFade>

        <RVOAnimateFade delay={0.4}>
          <RVOPeopleTrustResolvio />
        </RVOAnimateFade>
      </Grid>

      <Grid className={classes.appImagesWrapper} item={true} xs={12} lg={6}>
        <RVOAnimateFade
          animationProps={{
            initial: { x: 50, opacity: 0 },
            animate: {
              x: 0,
              opacity: 1,
              transition: {
                type: "spring",
                delay: 0.3,
              },
            },
          }}
        >
          <img
            className={classes.beschlussViewScreenshot}
            width={isSmallScreen ? "90%" : "100%"}
            height="auto"
            src="/app/screenshots/beschluss-browser.png"
          />
        </RVOAnimateFade>

        <Box className={classes.signWrapper}>
          <RVOAnimateFade
            animationProps={{
              initial: { x: 50, opacity: 0 },
              animate: {
                x: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  delay: 0.5,
                },
              },
            }}
          >
            <img
              className={classes.signWrapperShadow}
              width={isSmallScreen ? "40%" : "50%"}
              height="auto"
              src="/app/screenshots/sign.png"
            />
          </RVOAnimateFade>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RVOHeroSection;
